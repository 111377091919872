import React from "react";
import "./ForgotPassword.css";
import Logo from "../../../assets/logo_home.png";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
    const navigate = useNavigate();

  return (
    <div className="page-container login-page">
      <div className="auth-container forgot-pwd-page" style={{display:"flex", flexDirection:"column"}}>
        <form
        style={{width:"100%"}}
          method="POST"
          action="assets/php/actions.php?login"
          className="auth-form"
        >
          <div className="logo" style={{marginBottom:40}}>
            <img src={Logo} />
            {/* <center>Pursue Your Passion</center> */}
          </div>
          <hr className="horizontal-separator" />
          <h1 class="h5 forgot-password-header" style={{alignSelf:"flex-start"}}>Forgot Your Password?</h1>
          <div className="input-group" style={{marginTop:20}}>
            <input
              type="text"
              placeholder="Email or username"
              name="username_email"
              value=""
            />
          </div>
          <div className="input-group">
            <button className="btn" type="submit">
              Send verification code
            </button>
          </div>
        </form>
        <hr className="horizontal-separator" style={{marginTop:25}}/>
        <div class="btn-account">
            <a href="?login" onClick={(e)=>{
                e.preventDefault();
                navigate("/")
            }}><i class="bi bi-arrow-left-circle-fill"></i> Go Back To Login</a>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
