import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Home from "./pages/home/Home";
import Login from "./pages/Auth/Login/Login";
import Registration from "./pages/Auth/Registration/Registration";
import ForgotPassword from "./pages/Auth/ForgotPassword/ForgotPassword";
import VerifyUser from "./pages/Auth/VerifyUser/VerifyUser";
import { UserProvider } from "./context";
import UserProfile from "./components/profile/UserProfile";
import AddHobbies from "./pages/admin/AddHobbies";
import UploadHobbieIcons from "./pages/admin/UploadHobbieIcons";
import UserPolicy from "./pages/privacy/UserPolicy/UserPolicy";
import AddAvatar from "./pages/Auth/AddAvatar/AddAvatar";
import AddDetails from "./pages/Auth/AddDetails/AddDetails";
import UserDeletion from "./pages/privacy/UserDeletion/UserDeletion";
import SelectHobbyList from "./pages/Auth/SelectHobby/SelectHobbyList";
import UserSupport from "./pages/support/UserSupport";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // const pageName = location.pathname.substring(1);
    // localStorage.setItem("currentPage", pageName);

    // if (
    //   localStorage.getItem("currentPage") &&
    //   localStorage.getItem("currentPage") === "home"
    // ) {
    //   // alert("home");
    //   navigate("/home");
    //   console.log('Home')
    //   // location("")
    // }
    // console.log("Current page name:", pageName);
  }, [location]);

  return (
    <div className="app">
      <UserProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="forgot-password" element={<ForgotPassword />}></Route>
          <Route path="registration" element={<Registration />}></Route>
          <Route path="add-details" element={<AddDetails />}></Route>
          <Route path="add-avatar" element={<AddAvatar />}></Route>
          <Route path="select-hobbies" element={<SelectHobbyList />}></Route>
          <Route path="verify-user" element={<VerifyUser />}></Route>
          <Route path="home" element={<Home />}></Route>
          <Route path="profile" element={<UserProfile />}></Route>
          <Route path="addHobby" element={<AddHobbies />}></Route>
          <Route path="AddIcons" element={<UploadHobbieIcons />}></Route>
          <Route path="privacy-policies" element={<UserPolicy />}></Route>
          <Route path="delete-account" element={<UserDeletion />}></Route>
          <Route path="user-support" element={<UserSupport />}></Route>
        </Routes>
      </UserProvider>
    </div>
  );
}

export default App;
