import React, { useContext, useEffect, useState } from "react";
import "./LeftSideBar.css";
import Avatar from "../../common/avatar/Avatar";
import UserIcon from "../../../assets/icons/user.png";
import LoveIcon from "../../../assets/icons/heart.png";
import HomeIcon from "../../../assets/icons/home.png";
import { BASE_STORAGE_URL, BASE_URL, CLOUD_STORAGE_URL } from "../../../api/core";
import axios from "axios";
import { UserContext } from "../../../context";
import { useNavigate } from "react-router-dom";

const LeftSideBar = ({ setSwitchView, setSelectedHobby, feedLength }) => {
  const {userHobbies,setUserHobbies} = useContext(UserContext)
  const [hobbies, setHobbies] = useState([]);
  const [isFixed, setIsFixed] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHobbies = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(`${BASE_URL}/hobbies/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { hobbies } = response.data;
        // setHobbies(hobbies);
        
        //set hobbies globally to be accessed anywhere in the app via context
        setUserHobbies(hobbies)
      } catch (error) {
        console.error("Error fetching hobbies:", error);
      } finally {
      }
    };

    fetchHobbies();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Current scroll position
      const changePositionAt = 200; // Change position at 200px scroll

      if (scrollPosition >= changePositionAt) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Add event listener on mount
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const checkIfIconHasDigits = (firstFiveChars) => {
    let isDigits = /^\d+$/.test(firstFiveChars);
    if (isDigits) {
      //The first 5 characters are digits
      return true;
    } else {
      //The first 5 characters are not all digits
      return false;
    }
  };

  return (
    <div className="profile">
      <div className="content">
        <div className="profile-bg"></div>
        <div className="avatar-wrapper">
          <Avatar width={100} height={100} showUserDetails={true} />

          <div className="profile-icons">
            <div className="icons"   style={{ cursor: "pointer" }}
             onClick={() => {
              navigate("/profile");
             }}>
              <img src={UserIcon} />
            </div>
            <div className="icons"
             style={{ cursor: "pointer" }}
             onClick={() => {
               setSwitchView("Hobbylist");
             }}>
              <img src={LoveIcon} />
            </div>
            <div
              className="icons"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSwitchView("FeedView");
              }}
            >
              <img src={HomeIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className="hobbies">
        <h3>Your Hobbies</h3>

        <ul
          className="container-scrollbar"
          style={{
            position: isFixed && feedLength > 0 ? "fixed" : "relative",
            top: isFixed && feedLength ? "70px" : "auto",
            width: isFixed && feedLength ? "21%" : "auto",
            transition: "position 0.3s ease",
            background: " #f0eef6",
            maxHeight: "100%",
            overflowY: isFixed && feedLength ? "scroll" : "auto",
          }}
        >
          {userHobbies?.map((hobbie, index) => {
            return (
              <li
                style={{ display: "flex", gap: 20 }}
                key={hobbie?.id}
                onClick={() => {
                  setSwitchView("HobbyView");
                  setSelectedHobby(hobbie);
                }}
              >
                {checkIfIconHasDigits(hobbie.icon.substring(0, 5)) ? (
                  <div
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 14,
                      color: "white",
                      height: 25,
                      width: 25,
                      borderRadius: 100,
                      background: "#000",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {hobbie?.name.substr(0, 1)}
                  </div>
                ) : (
                  <div className="hobby-img-wrapper">
                  <img
                    style={{ width: 26, height: 26 }}
                    src={`${hobbie.icon}`}
                  />
                  </div>
                )}

                <p>{hobbie.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default LeftSideBar;
