import React, { useState } from "react";
import "./Registration.css";
import Logo from "../../../assets/logo_home.png";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../api/core";
import axios from "axios";
import { message } from "antd";

const Registration = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    retype_password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (formData.password !== formData.retype_password) {
      errors.retype_password = "Passwords do not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const hideLoading = message.loading("Registration in progress..", 0);

      try {
        const response = await axios.post(`${BASE_URL}/register`, formData);
        if (response) {
            const { token, user } = response.data;
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("token", token);
            navigate("/add-details");
        }
      } catch (error) {
       
        if (error.response) {
          const errorString = Object.keys(error.response.data.error)
            .map((key) => error.response.data.error[key])
            .join("\n");
          errorMsg(errorString);
        } else {
            alert("Registration failed. Please try again later.");
        }
        // Handle registration error (e.g., display error message)
      } finally {
        hideLoading();
      }
    }
  };

  return (
    <div className="page-container">
      {contextHolder}
      <div
        className="auth-container registration-container"
        style={{ marginBottom: 20, minHeight: 612 }}
      >
        <form onSubmit={handleSubmit} className="auth-form registration-form">
          <div className="logo registration-logo">
            <img src={Logo} alt="Logo" />
          </div>
          <center>Pursue Your Passion</center>
          <hr />

          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="input-group">
            <input
              type="password"
              id="floatingPassword"
              placeholder="New Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Retype Password"
              name="retype_password"
              value={formData.retype_password}
              onChange={handleChange}
            />
            {errors.retype_password && (
              <span className="error">{errors.retype_password}</span>
            )}
          </div>
          <div className="input-group">
            <button className="btn btn-primary" type="submit">
              Sign Up
            </button>
          </div>
          <p style={{ fontSize: "12px", paddingTop: "10px" }}>
            By clicking Sign Up, you agree to our{" "}
            <a
              href="?privacy-policy"
              onClick={(e) => {
                e.preventDefault();
                navigate("/privacy-policies");
              }}
            >
              Terms of use or Data Policy
            </a>
            .
          </p>
        </form>
        <hr />
        <div className="btn-account">
          <a
            href="?login"
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
          >
            Already have an account?
          </a>
        </div>
      </div>
    </div>
  );
};

export default Registration;
