import React, { useContext, useState } from "react";
import { FaPlus, FaBell, FaComment } from "react-icons/fa";
import "./Header.css";
import DynamicDropDown from "../../elements/DynamicDropDown/DynamicDropDown";
import Avatar from "../../common/avatar/Avatar";
import Modal from "../../common/modal/Modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageUpload from "../../common/ImageUploader/ImageUpload";

import SelectBoxHobby from "../../common/SelectHobbyBox/SelectHobbyBox";
import SelectBox from "../../common/SelectBox/SelectBox";
import { message } from "antd";
import { UserContext } from "../../../context";
import axios from "axios";
import { BASE_URL } from "../../../api/core";
import DropdownMenu from "../../elements/DynamicDropDown/dropdownmenu/DropdwonMenu";
import { useNavigate } from "react-router-dom";

const dropDownoptions = ["Option 1", "Option 2", "Option 3"];
const privacy = [
  { id: 1, name: "Public", alias: "public" },
  { id: 2, name: "Private", alias: "private" },
  { id: 3, name: "Friends", alias: "friends" },
];
const hobbies = [
  { id: 1, name: "Reading" },
  { id: 2, name: "Painting" },
  { id: 3, name: "Cooking" },
  { id: 4, name: "Gardening" },
  { id: 5, name: "Traveling" },
];

const Header = () => {
  const { userHobbies, setNewUserPost } = useContext(UserContext);

  const [selectedOption, setSelectedOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preview, setPreview] = useState(null);
  const [contentEditorValue, setContentEditorValue] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentItem, setCurrentItem] = useState("");
  const [selectHobbyError, setSelectHobbyError] = useState("");
  const [currentHobbyId, setCurrentHobbyId] = useState("");
  const [selectPrivacyError, setSelectPrivacyError] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const errorMsg = (msg) => {
    messageApi.open({
      type: "error",
      content: msg,
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSelect = (option) => {
    //profile dropdown
    setSelectedOption(option);
  };

  const handleEditorChange = (html) => {
    setContentEditorValue(html);
  };

  const handleStoreContent = async () => {
    if (currentHobbyId === "") {
      errorMsg("Select hobby before continue!");
      return;
      // hasError = true;
    }

    if (currentItem === "") {
      errorMsg("Select privacy before continue");
      return;
      // hasError = true;
    }

    if (!contentEditorValue) {
      errorMsg("Please add your hobby content");
      return;
    }

    const hideLoading = message.loading("saving your post...", 0);

    if (selectedImage) {
      handleImageUpload(hideLoading);
    } else {
      save(hideLoading, "");
    }
  };

  const handleImageUpload = async (hideLoading) => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      // setIsloading(true)

      const data = new FormData();
      data.append("file", selectedImage);
      data.append("cloud_name", "dxgmi9qni");
      data.append("upload_preset", "hobbie_app");

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dxgmi9qni/image/upload",
        data,
        config
      );

      const { secure_url } = response.data;

      if (secure_url) {
        save(hideLoading, secure_url);
      } else {
        alert("Image was not uploaded, try again!");
      }

      // handleSubmitContent(response.data.secure_url);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error setting up request:", error.message);
      }
    } finally {
    }
  };

  const save = async (hideLoading, uploadedImg) => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    const data = {
      content: contentEditorValue,
      title: "No title",
      user_id: user.id,
      hobby_id: currentHobbyId,
      visibility: currentItem,
      image_url: uploadedImg,
    };

    //post it to db
    try {
      const response = await axios.post(`${BASE_URL}/posts`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { feed } = response.data;
      if (feed) {
        closeModal();
        setNewUserPost(feed);
      }
      // Handle success, show a success message or navigate to another screen
    } catch (error) {
      // setShowBackdrop(false);
      console.error("Error adding post:", error);
      // Handle error, show an error message
    } finally {
      hideLoading();
      // setIsloading(false)
    }
  };

  return (
    <div className="navbar">
      {contextHolder}
      <Modal show={isModalOpen} onClose={closeModal}>
        <p className="modal-header">Create Post</p>
        <div className="model-header-bar">
          <Avatar
            mainStyle={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              height: 37,
              gap: 10,
            }}
            showUserDetails={true}
            userDetailsLayoutHeight={35}
            width={40}
            height={40}
            textSize={16}
            subTextSize={12}
          />
          <div className="hobby-select-box-wrapper">
            <div>
              <SelectBox
                style={
                  selectPrivacyError
                    ? { borderColor: "red", borderWidth: 1 }
                    : {}
                }
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
                options={privacy}
                title={"Select Status"}
              />
            </div>
            <div>
              <SelectBoxHobby
                style={
                  selectHobbyError ? { borderColor: "red", borderWidth: 1 } : {}
                }
                currentHobbyId={currentHobbyId}
                setCurrentHobbyId={setCurrentHobbyId}
                options={userHobbies}
                title={"Select Hobbies"}
              />
            </div>
          </div>
        </div>
        <ReactQuill
          placeholder="What's your hobby story for today?"
          theme="snow"
          modules={{ toolbar: false }}
          value={contentEditorValue}
          onChange={handleEditorChange}
        />
        <div className="image-uploader">
          {preview && (
            <img
              src={preview}
              alt="Preview"
              style={{ width: "50px", height: "50px", borderRadius: 10 }}
            />
          )}
          <p>Add an image to your hobby</p>
          <ImageUpload
            preview={preview}
            setPreview={setPreview}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        </div>
        <button
          style={{ cursor: "pointer" }}
          type="submit"
          className="post-btn"
          onClick={handleStoreContent}
        >
          Add Post
          {/* {loading ? <PageLoader  /> : " Add Post"} */}
        </button>
      </Modal>
      <div className="navbar-content">
        <div className="left-nav-content">
          <div className="logo" style={{cursor:"pointer"}} onClick={()=>navigate("/home")}></div>
          <input
            className="search-input"
            type="text"
            placeholder="Search hobbyist"
          />
        </div>
        <div className="icons">
          <button onClick={openModal} style={{background:"#ffdf00"}}>
            <FaPlus style={{ fontSize: 20 }} />
          </button>
          <button>
            <FaBell style={{ fontSize: 17 }} />
          </button>
          {/* <button>
            <FaComment style={{ fontSize: 17 }} />
          </button> */}
          <div className="avatar">
            <DropdownMenu />
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
