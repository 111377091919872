import { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

//custom navigation provider
export const UserProvider = ({ children }) => {
  // Define your initial state
  const [userGlobal, setUserGlobal]= useState(null)
  const [userHobbies, setUserHobbies] = useState([]);
  const [newUserPost, setNewUserPost] = useState(null);

  useEffect(()=>{
    const user  = localStorage.getItem("user");
    setUserGlobal(JSON.parse(user))
  },[])

  return (
    <UserContext.Provider
      value={{userGlobal,setUserGlobal, userHobbies, setUserHobbies, newUserPost, setNewUserPost }}
    >
      {children}
    </UserContext.Provider>
  );
};
