import React from "react";
import "./Post.css";
import FeedHeaderCard from "../../feedcard/FeedHeaderCard";
import Avatar from '../../../assets/watch-movie.png'
import { AiFillLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa6";

const Post = ({ feed, handleLikePress }) => {
  function convertToPlain(html){

    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

  return (
    <div className="post">
      <FeedHeaderCard feed={feed}/>
      <p className="post-content">{convertToPlain(feed?.content)}</p>
      {feed?.image_url && <img src={feed?.image_url} alt="Basketball game" />}
      <div className="activity-wrapper">
      <div className="activity-btns like" onClick={()=>handleLikePress(feed?.id)}>
          <AiFillLike /> 
          <p>{feed?.likes_count} likes</p>
        </div>
        <div className="activity-btns like">
          <FaRegComment /> 
          <p>{feed?.comments?.length} comments</p>
        </div>
        </div>
    </div>
  );
};

export default Post;
